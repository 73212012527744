import {has, isEmpty, isEqual, values} from 'lodash'
import {trackEvent} from '../../common/analytics'
import {Dictionary} from '../../common/types'
import {
  ConfigurableTimeSlot,
  Contact,
  DateRange,
  DeliveryTime,
  OrderIntakeMaterialOptionPayload,
  OrderIntakeOption,
  OrderRequest,
  PersistedOrderRequest,
  SlotConfiguration
} from '../../OrderIntake/declarations/types'
import {
  isPublicHolidayOrderingAvailable,
  isSaturdayOrderingAvailable,
  isSundayOrderingAvailable,
  selectMaterialOption
} from '../../Organisms/OrderIntake/utils'
import {isTimeBefore, parseTime} from '../../util/time'
import {
  DATA_BASE_TIME_FORMAT,
  evaluateDeliveryTime,
  onlyFullSlots
} from '../components/TimeScroller/TimeScroller.utils'
import {BUSINESS_HOURS_MIN_INTERVAL_DEFAULT} from '../declarations/constants'
import {DeactivationReason} from '../declarations/OrderIntake.enums'
import {isInvalidOrderIntakeOption} from '../Options/query'

type CustomerReference = {
  payload: {customerReference: string}
}

export type DistributedKeyField = 'orderRequests' | 'contact'

export type ResolvedMaterialSettings = {
  validMaterialOptions: OrderIntakeMaterialOptionPayload[]
  invalidMaterialOptions: OrderIntakeMaterialOptionPayload[]
  materialOption: OrderIntakeMaterialOptionPayload | undefined
}

export const areAllCustomerReferencesSame = (items: CustomerReference[]) => {
  const customerRefs = items.map((collection) => collection.payload.customerReference)

  const uniqueCustomerRefs = new Set(customerRefs)
  return uniqueCustomerRefs.size === 1
}

export const getMaterialOptionsByInvalidity = <T extends OrderIntakeMaterialOptionPayload>(
  materials: Dictionary<T[]>,
  invalid: boolean
) => {
  return values(materials)
    .flatMap((m) => m)
    .filter((m): m is T => m !== undefined && m?.invalid === invalid)
}

// eslint-disable-next-line complexity
export const getCustomMessageForDeliverDate = (dateRange: DateRange, t: Function) => {
  if (
    isPublicHolidayOrderingAvailable(dateRange) &&
    !isSaturdayOrderingAvailable(dateRange) &&
    !isSundayOrderingAvailable(dateRange)
  ) {
    return t('orderIntake.datePickerDisclaimerWeekends')
  }

  if (
    isSaturdayOrderingAvailable(dateRange) &&
    !isSundayOrderingAvailable(dateRange) &&
    !isPublicHolidayOrderingAvailable(dateRange)
  ) {
    return t('orderIntake.datePickerDisclaimerSundayPublicHoliday')
  }

  if (
    isSundayOrderingAvailable(dateRange) &&
    !isSaturdayOrderingAvailable(dateRange) &&
    !isPublicHolidayOrderingAvailable(dateRange)
  ) {
    return t('orderIntake.datePickerDisclaimerSaturdayPublicHoliday')
  }

  if (
    isSundayOrderingAvailable(dateRange) &&
    isSaturdayOrderingAvailable(dateRange) &&
    !isPublicHolidayOrderingAvailable(dateRange)
  ) {
    return t('orderIntake.datePickerDisclaimerPublicHoliday')
  }

  if (
    isPublicHolidayOrderingAvailable(dateRange) &&
    isSundayOrderingAvailable(dateRange) &&
    !isSaturdayOrderingAvailable(dateRange)
  ) {
    return t('orderIntake.datePickerDisclaimerSaturday')
  }

  if (
    isPublicHolidayOrderingAvailable(dateRange) &&
    isSaturdayOrderingAvailable(dateRange) &&
    !isSundayOrderingAvailable(dateRange)
  ) {
    return t('orderIntake.datePickerDisclaimerSunday')
  }

  if (
    !isPublicHolidayOrderingAvailable(dateRange) &&
    !isSaturdayOrderingAvailable(dateRange) &&
    !isSundayOrderingAvailable(dateRange)
  ) {
    return t('orderIntake.datePickerDisclaimer')
  }

  return undefined
}

export const isEmptyObject = (o?: object) => o && Object.values(o).every(isEmpty)

export const getDistributedKey = (field: DistributedKeyField, siteNumber: string) =>
  `orderIntake-${field}-${siteNumber}`

export const mergePersistedContact = (
  selectedSite: OrderIntakeOption,
  persistedContact: Contact | undefined,
  isContactPersistency: boolean
): Contact => {
  if (isContactPersistency && persistedContact) {
    trackEvent('hubOrderIntakePrepopulated', {
      ...persistedContact
    })
  }

  return isContactPersistency && persistedContact ? persistedContact : selectedSite.contact
}

export const mergePersistedOrders = (
  defaultOrderRequest: OrderRequest,
  selectedSite: OrderIntakeOption,
  persistedOrderRequestsFormCache: PersistedOrderRequest[] | undefined
): OrderRequest[] | undefined => {
  const persistedOrderRequests = persistedOrderRequestsFormCache
    ?.map((persistedOrderRequest): OrderRequest => {
      const validMaterialsFromSelectedSite = selectedSite.materials[
        persistedOrderRequest.materialEnteredNumber
      ].filter((material) => !material.invalid)

      return {
        ...defaultOrderRequest,
        payload: {
          ...defaultOrderRequest.payload,
          additionalDriverInfo:
            persistedOrderRequest.additionalDriverInfo ??
            defaultOrderRequest.payload.additionalDriverInfo,
          contractItemPositionNumber:
            selectMaterialOption(
              defaultOrderRequest.payload.deliveryDate,
              validMaterialsFromSelectedSite
            )?.material.contractItemPositionNumber ??
            persistedOrderRequest.contractItemPositionNumber,
          capacity: persistedOrderRequest.capacity,
          customerReference: persistedOrderRequest.customerReference,
          contractNumber: persistedOrderRequest.contractNumber,
          materialDescription: persistedOrderRequest.materialDescription,
          materialEnteredNumber: persistedOrderRequest.materialEnteredNumber,
          materialNumber: persistedOrderRequest.materialNumber,
          plantName: persistedOrderRequest.plantName,
          plantNumber: persistedOrderRequest.plantNumber,
          deliveryTime: defaultOrderRequest.initialDeliveryTime,
          isSendingConfirmationEmailUnChecked:
            persistedOrderRequest.isSendingConfirmationEmailUnChecked
        }
      }
    })
    .filter((or) => {
      const validMaterialsFromSelectedSite = selectedSite.materials[
        or.payload.materialEnteredNumber
      ].filter((material) => !material.invalid)

      return (
        has(selectedSite.materials, or.payload.materialEnteredNumber) &&
        !selectMaterialOption(
          defaultOrderRequest.payload.deliveryDate,
          validMaterialsFromSelectedSite
        )?.invalid
      )
    })
    .filter((or) => !isInvalidOrderIntakeOption(or))

  if (persistedOrderRequests) {
    trackEvent('hubOrderIntakePrepopulated', {
      ...persistedOrderRequests
    })
  }

  return !isEmpty(persistedOrderRequests) && persistedOrderRequests
    ? persistedOrderRequests
    : [defaultOrderRequest]
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getDeliveryTime = (
  defaultOrderRequest: OrderRequest,
  persistedDeliveryTime: DeliveryTime,
  isSlotsManagementEnabled: boolean,
  defaultMaterial?: OrderIntakeMaterialOptionPayload,
  timeZone?: string,
  slotConfiguration?: SlotConfiguration[],
  slots?: ConfigurableTimeSlot[]
): DeliveryTime => {
  const initialDeliveryDate = defaultOrderRequest.initialDeliveryDate
  const defaultDeliveryTime = defaultOrderRequest.initialDeliveryTime

  if (!isSlotsManagementEnabled || defaultMaterial === undefined) {
    return defaultDeliveryTime
  }

  const timeSlotsForDate = slots?.filter((s) => s.date === initialDeliveryDate)
  const businessHours = defaultMaterial.businessHours
  const minInterval = businessHours?.minInterval ?? BUSINESS_HOURS_MIN_INTERVAL_DEFAULT

  const evaluateArgs = [
    minInterval,
    DATA_BASE_TIME_FORMAT,
    initialDeliveryDate,
    defaultMaterial.defaultDeliveryWindow,
    defaultMaterial.businessHours,
    timeZone,
    false
  ] as const

  const deliveryTimeBySlots = evaluateDeliveryTime(
    ...evaluateArgs,
    slotConfiguration,
    timeSlotsForDate
  )

  let earliest = persistedDeliveryTime.earliest
  let latest = persistedDeliveryTime.latest

  const persistedEarliestTime = parseTime(persistedDeliveryTime.earliest)
  const persistedLatestTime = parseTime(persistedDeliveryTime.latest)

  const earliestTimeBySlots = parseTime(deliveryTimeBySlots.earliest)
  const latestTimeBySlots = parseTime(deliveryTimeBySlots.latest)

  if (isTimeBefore(earliestTimeBySlots, persistedEarliestTime))
    earliest = deliveryTimeBySlots.earliest

  if (isTimeBefore(latestTimeBySlots, persistedLatestTime)) latest = deliveryTimeBySlots.latest

  return {earliest: earliest, latest: latest}
}

export const setMaterialsValidityBySlots = (
  materialsDict: Dictionary<OrderIntakeMaterialOptionPayload[]>,
  deliveryDate: string,
  slotConfiguration: SlotConfiguration[]
): Dictionary<OrderIntakeMaterialOptionPayload[]> => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.entries(materialsDict).forEach(([materialEnteredNumber, materials]) => {
    materials.forEach((material) => {
      const slots = material.plant.configurableSlots
      const timeSlotsForDate = slots?.filter((s) => s.date === deliveryDate)
      const minInterval = material.businessHours?.minInterval ?? BUSINESS_HOURS_MIN_INTERVAL_DEFAULT

      if (timeSlotsForDate) {
        const isInvalid = onlyFullSlots(
          material.businessHours.earliestPossible,
          material.businessHours.latestPossible,
          minInterval,
          slotConfiguration,
          timeSlotsForDate
        )

        material.invalid = isInvalid
      }
    })
  })

  return materialsDict
}

export const isMaterialInvalidBySlots = (
  material: OrderIntakeMaterialOptionPayload,
  deliveryDate: string,
  slotConfiguration: SlotConfiguration[]
) => {
  const slots = material.plant.configurableSlots
  const timeSlotsForDate = slots?.filter((s) => s.date === deliveryDate)
  const minInterval = material.businessHours?.minInterval ?? BUSINESS_HOURS_MIN_INTERVAL_DEFAULT

  if (timeSlotsForDate) {
    return onlyFullSlots(
      material.businessHours.earliestPossible,
      material.businessHours.latestPossible,
      minInterval,
      slotConfiguration,
      timeSlotsForDate
    )
  }

  return false
}

export const isConsecutiveContractDate = (
  currentMaterial: OrderIntakeMaterialOptionPayload,
  lastMaterial: OrderIntakeMaterialOptionPayload
) => {
  const {from: currentFrom, to: currentTo} = currentMaterial?.dateRange ?? {
    from: '',
    to: ''
  }
  const {from: lastFrom, to: lastTo} = lastMaterial?.dateRange ?? {
    from: '',
    to: ''
  }

  const currentStart = Date.parse(currentFrom)
  const currentEnd = Date.parse(currentTo)
  const lastStart = Date.parse(lastFrom)
  const lastEnd = Date.parse(lastTo)

  const oneDay = 86400000

  return currentStart === lastEnd + oneDay || lastStart === currentEnd + oneDay
}

export const sortByDate = (
  array: OrderIntakeMaterialOptionPayload[]
): OrderIntakeMaterialOptionPayload[] => {
  return array.sort((a, b) => {
    const dateA = Date.parse(a.dateRange.from)
    const dateB = Date.parse(b.dateRange.from)
    return dateA - dateB
  })
}

export const sortOptionsByMaterialDescription = (
  options: OrderIntakeMaterialOptionPayload[]
): OrderIntakeMaterialOptionPayload[] => {
  return options.sort((a, b) =>
    a.material.materialDescription.localeCompare(b.material.materialDescription)
  )
}

export const filterDuplicateConsecutiveMaterials = (
  options: OrderIntakeMaterialOptionPayload[]
) => {
  const sortedOptions = sortOptionsByMaterialDescription(sortByDate(options))

  return sortedOptions.filter((currentValue, i, array) => {
    const last = array[i - 1]
    const isSameMaterialDescription =
      currentValue.material.materialDescription === last?.material.materialDescription
    const isConsecutive = isConsecutiveContractDate(currentValue, last)

    return !(isSameMaterialDescription && isConsecutive)
  })
}

export const filterFlaggedInvalidMaterials = (materials: OrderIntakeMaterialOptionPayload[]) => {
  return materials.filter(
    (material) => material.deactivationReason !== (DeactivationReason.FLAGGED as string)
  )
}

// Half duplicates are materials that are the same but comes from different plants
export const filterByMaterialNumberAndPlant = (
  filteredInvalidMaterialOptions: OrderIntakeMaterialOptionPayload[],
  validMaterialOptions: OrderIntakeMaterialOptionPayload[]
): OrderIntakeMaterialOptionPayload[] => {
  return filteredInvalidMaterialOptions.filter((invalidOption) => {
    return !validMaterialOptions.some(
      (validOption) =>
        validOption.plant.country === 'NO' ||
        (validOption.material.materialEnteredNumber ===
          invalidOption.material.materialEnteredNumber &&
          validOption.plant.plantName !== invalidOption.plant.plantName)
    )
  })
}

export const filterMaterialsByValidity = (
  valid: boolean,
  materials: Dictionary<OrderIntakeMaterialOptionPayload[]>
) => {
  return Object.values(materials)
    .flatMap((item) => item)
    .filter((item) => (valid ? !item.invalid : item.invalid))
}

export const getValidAndInvalidMaterialsForDelivery = (
  materialOptions: OrderIntakeMaterialOptionPayload[],
  materials: Dictionary<OrderIntakeMaterialOptionPayload[]>,
  materialEnteredNumber: string,
  deliveryDate: string,
  plantName: string,
  checkValidityBySlots: boolean,
  slotConfiguration: SlotConfiguration[] | undefined,
  shouldFlaggedMaterialsBeFiltered: boolean
): ResolvedMaterialSettings => {
  let validMaterialOptions: OrderIntakeMaterialOptionPayload[] = []
  let invalidMaterialOptions: OrderIntakeMaterialOptionPayload[] = []

  materialOptions.forEach((material) => {
    const invalidBySlots =
      checkValidityBySlots &&
      slotConfiguration &&
      isMaterialInvalidBySlots(material, deliveryDate, slotConfiguration)
    if (material.invalid || invalidBySlots) {
      const resolvedMaterial = {...material}
      resolvedMaterial.invalid = true
      invalidMaterialOptions.push(resolvedMaterial)
    } else {
      const resolvedMaterial = {...material}
      resolvedMaterial.invalid = false
      validMaterialOptions.push(resolvedMaterial)
    }
  })

  validMaterialOptions = filterDuplicateConsecutiveMaterials(validMaterialOptions)

  const filteredInvalidMaterialOptions = shouldFlaggedMaterialsBeFiltered
    ? filterFlaggedInvalidMaterials(invalidMaterialOptions)
    : invalidMaterialOptions

  // Do not show material duplicates from different plants in the material dropdown for delivered Order Intake
  const uniqueInvalidMaterialOptions = filterByMaterialNumberAndPlant(
    filteredInvalidMaterialOptions,
    validMaterialOptions
  )

  invalidMaterialOptions = filterDuplicateConsecutiveMaterials(uniqueInvalidMaterialOptions)

  const validMaterialOptionsFromMaterialEnteredNumber: OrderIntakeMaterialOptionPayload[] =
    Object.values(materials[materialEnteredNumber])
      .flatMap((item) => item)
      .filter((item) => !item.invalid)
      .filter((item) => item.plant.plantName === plantName) // When we have duplicate materials with different plants, we need to filter out the selected plant

  const materialOption = selectMaterialOption(
    deliveryDate,
    validMaterialOptionsFromMaterialEnteredNumber
  )

  return {validMaterialOptions, invalidMaterialOptions, materialOption}
}

export const getValidAndInvalidMaterialsForCollect = (
  materials: Dictionary<OrderIntakeMaterialOptionPayload[]>,
  selectedMaterial: OrderIntakeMaterialOptionPayload,
  materialEnteredNumber: string,
  deliveryDate: string,
  shouldFlaggedMaterialsBeFiltered: boolean
): ResolvedMaterialSettings => {
  const filterByValidity = (valid: boolean) => {
    return Object.values(materials)
      .flatMap((item) => item)
      .filter((item) => (valid ? !item.invalid : item.invalid))
  }

  const validMaterialOptions: OrderIntakeMaterialOptionPayload[] =
    filterDuplicateConsecutiveMaterials(filterByValidity(true))

  const filteredInvalidMaterialOptions = shouldFlaggedMaterialsBeFiltered
    ? filterFlaggedInvalidMaterials(filterByValidity(false))
    : filterByValidity(false)

  const invalidMaterialOptions: OrderIntakeMaterialOptionPayload[] =
    filterDuplicateConsecutiveMaterials(filteredInvalidMaterialOptions)

  const validMaterialOptionsFromMaterialEnteredNumber: OrderIntakeMaterialOptionPayload[] =
    Object.values(materials[materialEnteredNumber])
      .flatMap((item) => item)
      .filter((item) => !item.invalid)

  const materialOption = selectMaterialOption(
    deliveryDate,
    validMaterialOptionsFromMaterialEnteredNumber,
    selectedMaterial
  )

  return {validMaterialOptions, invalidMaterialOptions, materialOption}
}

export const getValidMaterialOptions = (
  selectedSite: OrderIntakeOption,
  isSlotsManagementEnabled: boolean,
  orders: OrderRequest[],
  defaultOrderRequest: OrderRequest,
  slotConfiguration: SlotConfiguration[] | undefined,
  isMaterialInvalidBySlots: (
    material: OrderIntakeMaterialOptionPayload,
    deliveryDate: string,
    slotConfiguration: SlotConfiguration[]
  ) => boolean,
  useConfigurableSlots: boolean = true
) => {
  const materialOptions = Object.values(selectedSite.materials).flatMap((m) => m)
  const checkValidityBySlots = isSlotsManagementEnabled && useConfigurableSlots

  const deliveryDate =
    orders.length === 0
      ? defaultOrderRequest?.payload?.deliveryDate
      : orders[0].payload.deliveryDate

  const validMaterialOptions: OrderIntakeMaterialOptionPayload[] = materialOptions.filter(
    (material) => {
      const invalidBySlots =
        checkValidityBySlots &&
        slotConfiguration &&
        isMaterialInvalidBySlots(material, deliveryDate, slotConfiguration)
      return !material.invalid && !invalidBySlots
    }
  )

  return validMaterialOptions
}

export const removeDuplicatesObjects = <T>(arr: T[]): T[] => {
  return arr.reduce((acc: T[], current: T) => {
    if (!acc.some((item) => isEqual(item, current))) {
      acc.push(current)
    }
    return acc
  }, [])
}
