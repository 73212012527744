import moment from 'moment'

import {
  areDatesAvailable,
  getEarliestDeliveryDate,
  getUnavailableDaysFromTimeSlots,
  isDateAvailable
} from '../../Organisms/OrderIntake/utils'
import {getDateExceptions} from '../BulkCement/containers/DateFilter'
import {DEFAULT_CUSTOMER_REFERENCE} from '../declarations/constants'
import {
  OrderIntakeMaterialOptionPayload,
  OrderIntakeOption,
  OrderIntakePayload,
  OrderRequest,
  SlotConfiguration
} from '../declarations/types'
import {ShippingType} from '../declarations/OrderIntake.enums'

export const updateOrderRequestByMaterialChange = (
  orderRequest: OrderRequest,
  selectedSite: OrderIntakeOption,
  materialOption: OrderIntakeMaterialOptionPayload,
  slotConfiguration: SlotConfiguration[] | undefined
): OrderRequest => {
  const materialEnteredNumber = materialOption.material.materialEnteredNumber

  const availableMaterials = selectedSite.materials[materialEnteredNumber].filter(
    (item) => !item.invalid
  )
  const materials = availableMaterials
    .filter((materialOption) =>
      isDateAvailable(
        orderRequest.payload.deliveryDate,
        materialOption.dateRange,
        materialOption.businessHours.timeZone
      )
    )
    .filter((item) => !item.invalid)
    .filter((item) => item.plant.plantNumber === materialOption.plant.plantNumber) // When we have duplicate materials with different plants, we need to filter out the selected plant

  const validMaterialOption = materials[0] ?? availableMaterials[0]
  const {date, isDateChange} = selectedSite
    ? getEarliestDeliveryDate(
        orderRequest.payload.deliveryDate,
        validMaterialOption?.dateRange || [],
        validMaterialOption?.businessHours.timeZone
      )
    : {
        date: orderRequest.payload.deliveryDate,
        isDateChange: false
      }

  const isNoDatesAvailable = !areDatesAvailable(
    validMaterialOption.dateRange,
    getDateExceptions(validMaterialOption.businessHours, validMaterialOption.dateRange),
    getUnavailableDaysFromTimeSlots(
      validMaterialOption.businessHours,
      slotConfiguration,
      validMaterialOption.plant.configurableSlots
    ).map((s) => moment(s))
  )

  const customerReference = materialOption.customerReference || DEFAULT_CUSTOMER_REFERENCE

  /*
     When we have duplicate materials with different plants, for COLLECT orders we don't
     have "Closest plant", so user is free to decide which plant would select even for 
     duplicated materials. therefor we should pick the contractItemPositionNumber from the
      selected material option and not from the valid material option.
  */
  const contractItemPositionNumberPerShippingType =
    materialOption.shippingType === ShippingType.DELIVER
      ? validMaterialOption?.material.contractItemPositionNumber
      : materialOption.material.contractItemPositionNumber

  const payload: OrderIntakePayload = {
    ...orderRequest.payload,
    materialEnteredNumber,
    materialNumber: validMaterialOption?.material.materialNumber,
    materialDescription: validMaterialOption?.material.materialDescription,
    plantName: materialOption.plant.plantName,
    plantNumber: materialOption.plant.plantNumber,
    deliveryDate: date,
    customerReference,
    contractNumber: validMaterialOption?.material.contractNumber,
    contractItemPositionNumber: contractItemPositionNumberPerShippingType,
    capacity: {
      ...orderRequest.payload.capacity,
      quantityType: validMaterialOption?.material.quantityType
    }
  }
  return {
    ...orderRequest,
    isMaterialHighlighted: false,
    isDateChange,
    isNoDatesAvailable,
    payload
  }
}

// eslint-disable-next-line import/no-default-export
export default function (
  orderRequest$: OrderRequest,
  selectedSite: OrderIntakeOption,
  materialOption: OrderIntakeMaterialOptionPayload,
  slotConfiguration: SlotConfiguration[] | undefined
) {
  const orderRequest = updateOrderRequestByMaterialChange(
    orderRequest$,
    selectedSite,
    materialOption,
    slotConfiguration
  )

  const isOrderRequestConfirmed = !orderRequest.isDateChange
  return {orderRequest, isOrderRequestConfirmed}
}
